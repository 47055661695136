var config = {};
config.env = 'prod';

//AWS
config.aws = {};
config.aws.apiRoot = "https://api-gimsmedinfo.amgen.com/";

//SSO
config.sso = {};
config.sso.url = "";

//api-key
config.api = {};
config.api.key = 'oHuVmBN4Ub2CBvf71WOZW7jZZBOyMGJm4aluWGjt';

export default config;
